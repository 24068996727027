<template>
<div class="purchase-detail-bdi-list" v-if="budgetDetailList && budgetDetailList.length > 0">
  <div class="purchase-detail-bdi" v-for="item in budgetDetailList" :key="item.id">
    <div class="t-info-text" style="font-weight: 600;">预算ID：{{item.code}}</div>
    <div class="p-chart">
      <div class="chart-item">
        <rate :data="item.rate1Data"></rate>
      </div>
      <div class="chart-item">
        <rate :data="item.rate2Data"></rate>
      </div>
    </div>
    <div class="detail-list">
      <div class="budget-info" :class="{'budget-info-l': item.purchaseBatchDetailList.length > 0}">
        <div class="info-text">名称：{{item.goodsName}}</div>
        <div class="info-text">申请部门：{{item.budgetApplyOrgName}}</div>
        <div class="info-text" style="min-width:34%">单价：{{item.price}}元</div>
        <div class="info-text" style="min-width:1%">数量：{{item.num}}</div>
        <div class="info-text" style="min-width:1%">总价：{{item.total}}元</div>
      </div>
      <div class="t-info-text" style="font-weight: 600;padding: 0 10px 6px;" v-if="item.purchaseBatchDetailList.length > 0">该笔预算关联采购</div>
      <div class="budget-info-2" v-for="(v, index) in item.purchaseBatchDetailList" :key="index">
        <div class="info-text-2">采购ID：{{v.code}}</div>
        <div class="info-text-2">
          <span>名称：{{v.goodsName}}</span><i v-if="item.goodsId !== v.goodsId" class="fmico fmico-info-solid"></i></div>
        <div class="info-text-2">申请部门：{{v.orgName}}<i v-if="item.budgetApplyOrgId !== v.orgId" class="fmico fmico-info-solid"></i></div>
        <div class="info-text-2">单价：{{v.price}}元 <i v-if="v.price > item.price" class="fmico fmico-paixu-shengxu"></i></div>
        <div class="info-text-2">数量：{{v.num}}<i v-if="item.rate2Data.warning" class="fmico fmico-paixu-shengxu"></i></div>
        <div class="info-text-2">总价：{{v.total}}元<i v-if="item.rate1Data.warning" class="fmico fmico-paixu-shengxu"></i></div>
        <div class="info-text-2"></div>
      </div>
    </div>
  </div>
</div>
<div v-else style="margin: 20px;">无关联预算</div>
</template>

<script>
import rate from './rate'

import {
  budgetDetailRequest
} from '@/api'

export default {
  components: {
    rate
  },
  props: {
    budgetDetailIds: { type: Array, defualt: () => [] },
    type: { type: String},
    purchaseBatchDetaiId: {
      type: Number,
      defualt: null
    }
  },
  watch: {
    budgetDetailIds: {
      deep: true,
      handler () {
        this.loadData()
      },
      immediate: true
    }
  },
  data () {
    return {
      budgetDetailList: []
    }
  },
  methods: {
    getRate1Data (item, title, key) {
      let data = {
        warning: false,
        title: title,
        data: [],
        dw: key === 'num' ? '' : '元',
        color: ['#F5E2DF', '#FFCFD7', '#FF9EA3']
      }
      let totalM = item[key]
      let usedTotalM = 0
      let usedList = item.purchaseBatchDetailList.map(v => {
        usedTotalM += v[key] || 0
        return {
          name: v.code,
          value: v[key] || 0
        }
      })
      if (totalM === usedTotalM) {
        data.data = usedList;
      } else if (totalM > usedTotalM) {
        data.data = usedList;
        data.data.push({
          name: '剩余',
          value: totalM - usedTotalM,
          itemStyle: {
            color: '#A0DBE3'
          },
          label: {
            show: true,
            position: 'center',
            formatter: function (param) {
              return param.name + ' ' + param.value + (data.dw || '')
            }
          }
        })
      } else {
        data.warning = true
        data.data.push({
          name: '超出',
          value: usedTotalM - totalM,
          itemStyle: {
            color: '#FB6D8F'
          },
          label: {
            show: true,
            position: 'center',
            formatter: function (param) {
              return param.name + ' ' + param.value + (data.dw || '')
            }
          }
        })
      }
      return data
    },
    async loadData () {
      this.budgetDetailList = []
      if (this.budgetDetailIds.length === 0) {
        return
      }
      let datas = await budgetDetailRequest.get({
        needPurchaseBatchDetail: 1,
        ids: this.budgetDetailIds.join(',')
      })
      datas.forEach(v => {
        v.rate1Data = this.getRate1Data(v, '总价', 'total')
        v.rate2Data = this.getRate1Data(v, '数量', 'num')
      })
      this.budgetDetailList = datas
    },
  }
}
</script>

<style scoped lang="less">
.purchase-detail-bdi-list {
  width: 100%;
  .t-info-text {
    padding: 10px 10px 0;
  }
  .budget-info-l {
    border-bottom: 1px solid #e8eaec;
  }
  .budget-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 150px;
    padding-left: 20px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    .info-text {
      min-width: 45%;
      font-size: 12px;
      line-height: 22px;
    }
    .info-text-2 {
      width: 40%;
      font-size: 12px;
      line-height: 22px;
      display: flex;
      justify-content: space-between;
      margin-right: 10px
    }
    i {
      color: #FB6D8F;
      padding-left: 10px;
    }
  }
  .budget-info-2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    min-width: 150px;
    padding:0 20px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    .info-text-2 {
      width: 44%;
      font-size: 12px;
      line-height: 22px;
      display: flex;
      justify-content: space-between;
      margin-right: 10px
    }
    i {
      color: #FB6D8F;
      padding-left: 10px;
    }
  }
  .purchase-detail-bdi {
    border: 1px solid #e8eaec;
    border-radius: 5px;
    margin: 10px 0;
  }
  .p-chart {
    height: 180px;
    display: flex;
    justify-content: space-around;
    .chart-item {
      width: 150px;
      height: 100%;
    }
  }
  .detail-list {
  }
  .detail-list-c {
    width: calc(100%);
  }
}
</style>