<template>
  <fm-modal title="提交" v-model="modal" width="600px" v-if="modal">
    <fm-form label-align="left">
      <fm-form-item label="申请部门分管领导" v-if="updateKeys && updateKeys.split(',').includes('leadWorkerId')">
        <fm-select absolute filterable :clearable="true" v-model="formData.leadWorkerId">
          <fm-option v-for="item in leadWorkerList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="对口职能部门" v-if="updateKeys && updateKeys.split(',').includes('busOrgId')">
        <fm-select absolute filterable :clearable="true" v-model="formData.busOrgId">
          <fm-option v-for="item in busOrgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="采购部门分管领导" v-if="updateKeys && updateKeys.split(',').includes('purchaseLeadWorkerId')">
        <fm-select absolute filterable :clearable="true" v-model="formData.purchaseLeadWorkerId">
          <fm-option v-for="item in purchaseLeadWorkerList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="院长" v-if="updateKeys && updateKeys.split(',').includes('deanWorkerId')">
        <fm-select absolute filterable :clearable="true" v-model="formData.deanWorkerId">
          <fm-option v-for="item in deanWorkerList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
    </fm-form>
    <div class="model-btn">
      <fm-btn style="margin-right: 30px;" @click="formSubmit">提交</fm-btn>
      <fm-btn @click="modal = false">取消</fm-btn>
    </div>
  </fm-modal>
</template>

<script>
export default {
  props: {
    updateKeys: { type: String, defualt: null },
    batchData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {},
      modal: false
    }
  },
  computed: {
    // leadWorkerId busOrgId purchaseLeadWorkerId deanWorkerId
    leadWorkerList () {
      let leadWorkerIds = this.batchData && this.batchData.leadWorkerIds ? this.batchData.leadWorkerIds.split(',').map(v => Number(v)) : []
      return this.$store.getters.workerList.filter(v => leadWorkerIds.includes(v.data.id))
    },
    busOrgList () {
      let busOrgIds = this.batchData && this.batchData.busOrgIds ? this.batchData.busOrgIds.split(',').map(v => Number(v)) : []
      return this.$store.getters.orgList.filter(v => busOrgIds.includes(v.data.id))
    },
    purchaseLeadWorkerList () {
      let purchaseLeadWorkerIds = this.batchData && this.batchData.purchaseLeadWorkerIds ? this.batchData.purchaseLeadWorkerIds.split(',').map(v => Number(v)) : []
      return this.$store.getters.workerList.filter(v => purchaseLeadWorkerIds.includes(v.data.id))
    },
    deanWorkerList () {
      let deanWorkerIds = this.batchData && this.batchData.deanWorkerIds ? this.batchData.deanWorkerIds.split(',').map(v => Number(v)) : []
      return this.$store.getters.workerList.filter(v => deanWorkerIds.includes(v.data.id))
    }
  },
  methods: {
    async formSubmit () {
      let pass = true
      this.updateKeys.split(',').forEach(v => {
        if (!this.formData[v]) {
          pass = false
        }
      })
      if (!pass) {
        this.$notify({
          title: '系统提示',
          message: '请检查表单',
          type: 'info'
        })
      } else {
        this.$emit('submit', this.formData)
        this.modal = false
      }
    }
  }
}
</script>

<style scoped lang="less">
.model-btn {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>