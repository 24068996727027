<template>
  <div class="detial-item">
    <div class="base-info">
      <base-info size="auto" :data="data"></base-info>
    </div>
    <div class="step-list">
      <step-info :statusMap="statusMap" :currentStatus="data.status" :config="config" :recodeList="data.statusActionRecord || []"></step-info>
    </div>
    <div class="deal-recode" :class="{'show-detail-record': show}">
      <record :statusMap="statusMap" :recordList="data.statusActionRecord || []"></record>
    </div>
    <div class="deal-recode" :class="{'show-detail-record': show}">
      <budget-detail-info :purchaseBatchDetaiId="data.id" :budgetDetailIds="data.budgetDetailIds ? data.budgetDetailIds.split(',') : []"></budget-detail-info>
    </div>
    <a class="recode-switch" href="javascript:;" @click="show = !show">
      <div style="width: 20px;height: 20px;transition: 0.5s;transform:rotate(90deg);" :class="{'back-i': show}">
        <i class="icon-jiantouyou iconfont"></i>
      </div>
    </a>
    <div class="right-btns">
      <fm-btn @click="clickAction(item)" v-for="item in data.actions.filter(v => v.key === 'back_status_switch')" :key="item.key">{{item.label}}</fm-btn>
    </div>
  </div>
</template>

<script>
import baseInfo from './baseInfo'
import record from './record'
import stepInfo from './stepInfo'
import budgetDetailInfo from './budgetDetailInfo'

import {
  purchaseBatchDetailsRequest
} from '@/api'

export default {
  components: {
    baseInfo,
    record,
    stepInfo,
    budgetDetailInfo
  },
  data () {
    return {
      show: false
    }
  },
  props: {
    data: { type: Object, defualt: () => null },
    config: { type: Array, defualt: () => [] },
    statusMap: { type: Object, defualt: () => {} }
  },
  methods: {
    async clickAction (action) {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定' + action.label + '该采购明细吗?'})
      if (result) {
        await purchaseBatchDetailsRequest.switchStatus(this.data.id, {
          statusActionKey: action.key,
          statusActionRecordId: action.statusActionRecordId,
          remark: this.reamrk
        })
        this.$emit('dataUpdate')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.detial-item {
  position: relative;
  background: #fafafa;
  padding: 10px 100px 0 20px;
  border-radius: 3px;
  .base-info {
    padding-top: 10px;
  }
  .right-btns {
    position: absolute;
    top: 0;
    right: 0px;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .recode-switch {
    display: flex;
    justify-content: center;
  }
  .icon-jiantouyou {
    font-size: 20px;
    color: #F4628F;
    line-height: 1.2;
  }
  .back-i {
    transform:rotate(-90deg) !important;
  }
  .deal-recode {
    padding-top: 0px;
    transition: 0.5s;
    border-top: 1px solid #e8eaec;
    overflow-y:auto;
    opacity: 0;
    height: 0;
  }
  .step-list {
    overflow-x: auto;
  }
  .show-detail-record {
    padding-top: 10px;
    opacity: 1;
    overflow-y: visible;
    height: auto;
  }
}
</style>