<template>
  <div class="purchase-detail-deal-block">
    <div class="left">
      <div class="sign-item" v-for="item in dataList" :key="item.id">
        <sign-item @clickItem="clickItem" :data="item" :active="chooseData && chooseData.id === item.id"></sign-item>
      </div>
    </div>
    <div class="right" v-if="chooseData && chooseData.id">
      <div class="detail-info">
        <div class="base-info">
          <fm-title title-text="基础信息"></fm-title>
          <base-info :data="chooseData"></base-info>
        </div>
        <div class="deal-glfx">
          <fm-title title-text="关联使用情况分析"></fm-title>
          <budget-detail-info :budgetDetailIds="budgetDetailIds"></budget-detail-info>
        </div>
        <div class="deal-recode">
          <fm-title title-text="处理记录"></fm-title>
          <record :statusMap="statusMap" :recordList="chooseData.statusActionRecord || []"></record>
        </div>
      </div>
      <div class="input-area">
        <fm-title title-text="我的处理"></fm-title>
        <fm-input-new type="textarea" v-model="reamrk" class="input-area-input"></fm-input-new>
      </div>
      <div class="right-btns">
        <fm-btn @click="clickAction(item)" v-for="item in chooseData.actions" :key="item.key">{{item.label}}</fm-btn>
      </div>
    </div>
    <detail-form @dataUpdate="dataUpdate" :orgList="orgList" :budgetDetailList="budgetDetailList" ref="dealBlockForm" :data="chooseData"></detail-form>
    <submit-form @submit="submit" ref="dealBlockSubmitForm" :updateKeys="chooseAction ? chooseAction.updateKeys : null" :batchData="batchData"></submit-form>
  </div>
</template>

<script>
import detailForm from './form'
import signItem from './signItem'
import baseInfo from './baseInfo'
import record from './record'
import submitForm from './submitForm'
import budgetDetailInfo from './budgetDetailInfo'

import {
  purchaseBatchDetailsRequest
} from '@/api'

export default {
  components: {
    detailForm,
    signItem,
    baseInfo,
    record,
    submitForm,
    budgetDetailInfo
  },
  props: {
    dataList: { type: Array, defualt: () => [] },
    orgList: { type: Array, defualt: () => [] },
    batchData: { type: Object, defualt: () => null },
    statusMap: { type: Object, defualt: () => {} },
    budgetDetailList: { type: Array, defualt: () => [] },
  },
  computed: {
    budgetDetailIds () {
      return this.chooseData && this.chooseData.budgetDetailIds ? this.chooseData.budgetDetailIds.split(',') : []
    }
  },
  watch: {
    dataList: {
      deep: true,
      handler () {
        if (this.chooseData && this.chooseData.id) {
          this.chooseData = this.dataList.find(v => v.id === this.chooseData.id)
        }
      },
      immediate: true
    }
  },
  data () {
    return {
      chooseData: null,
      reamrk: '',
      chooseAction: null
    }
  },
  methods: {
    async dataUpdate () {
      await this.$emit('dataUpdate')
    },
    clickItem (data) {
      this.chooseData = this.chooseData && this.chooseData.id === data.id ? null : data
    },
    async submit (p) {
      let parm = {
        statusActionKey: this.chooseAction.key,
        statusActionRecordId: this.chooseAction.statusActionRecordId,
        remark: this.reamrk,
      }
      this.chooseAction.updateKeys.split(',').forEach(v => {
        parm[v] = p[v]
      })
      await purchaseBatchDetailsRequest.switchStatus(this.chooseData.id, parm)
      this.reamrk = ''
      this.dataUpdate()
    },
    async clickAction (action) {
      if (action.key === 'edit') {
        this.$refs.dealBlockForm.modal = true
      } else if (action.key === 'del') {
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除该采购明细吗?'})
        if (result) {
          await purchaseBatchDetailsRequest.del(this.chooseData.id)
          this.dataUpdate()
        }
      } else {
        if (action.updateKeys) {
          this.chooseAction = action
          this.$refs.dealBlockSubmitForm.modal = true
          return
        }
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定' + action.label + '该采购明细吗?'})
        if (result) {
          await purchaseBatchDetailsRequest.switchStatus(this.chooseData.id, {
            statusActionKey: action.key,
            statusActionRecordId: action.statusActionRecordId,
            remark: this.reamrk
          })
          this.reamrk = ''
          this.dataUpdate()
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.purchase-detail-deal-block {
  padding: 20px;
  display: flex;
  .left {
    height: 100%;
    overflow-y: auto;
    width: 300px;
    padding-right: 20px;
  }
  .right {
    height: 100%;
    flex: 1;
    overflow-y: auto;
    border: 1px solid #e8eaec;
    display: flex;
    flex-direction: column;
    position: relative;
    .detail-info {
      height: calc(100% - 180px);
      overflow-y: auto;
    }
    .input-area {
      border-top: 1px solid #e8eaec;
      position: absolute;
      width: calc(100% - 40px);
      padding: 0 20px;
      bottom: 60px;
      height: 120px;
      .input-area-input {
        width: 100%;
        height: calc(100% - 40px);
      }
    }
    .right-btns {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 60px;
      align-items: center;
      display: flex;
      justify-content: space-around;
    }
  }
  .sign-item {
    margin-bottom: 10px;
    width: 100%;
  }
  .deal-recode {
    margin:0 20px 20px;
    padding-bottom: 20px;
  }
  .deal-glfx {
    border-bottom: 1px solid #e8eaec;
    padding-bottom: 10px;
    margin:0 20px 0;
  }
  .base-info {
    margin:0 20px 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #e8eaec;
  }
}
</style>