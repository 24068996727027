<template>
<div class="purchase-detail-bdi-list" v-if="budgetDetailList && budgetDetailList.length > 0">
  <div class="purchase-detail-bdi" v-for="item in budgetDetailList" :key="item.id">
    <div class="p-chart" v-if="type !== 'column'">
      <div class="chart-item">
        <rate :data="item.rate1Data"></rate>
      </div>
      <div class="chart-item">
        <rate :data="item.rate2Data"></rate>
      </div>
    </div>
    <div class="detail-list" :class="{'detail-list-c': type === 'column'}">
      <div class="budget-info">
        <fm-title title-text="预算明细"></fm-title>
        <div class="info-text">{{item.code}}</div>
        <div class="info-text">名称：{{item.goodsName}}</div>
        <div class="info-text">申请部门：{{item.budgetApplyOrgName}}</div>
        <div class="info-text">单价：{{item.price}}元</div>
        <div class="info-text">数量：{{item.num}}</div>
        <div class="info-text">总价：{{item.total}}元</div>
      </div>
      <div class="budget-info" v-for="(v, index) in item.purchaseBatchDetailList" :key="index">
        <fm-title title-text="使用该预算的采购" v-if="index===0"></fm-title>
        <div style="height: 48px" v-else></div>
        <div class="info-text">{{v.code}}</div>
        <div class="info-text">名称：{{v.goodsName}}<i v-if="item.goodsId !== v.goodsId" class="fmico fmico-info-solid"></i></div>
        <div class="info-text">申请部门：{{v.orgName}}<i v-if="item.budgetApplyOrgId !== v.orgId" class="fmico fmico-info-solid"></i></div>
        <div class="info-text">单价：{{v.price}}元 <i v-if="v.price > item.price" class="fmico fmico-paixu-shengxu"></i></div>
        <div class="info-text">数量：{{v.num}}<i v-if="item.rate2Data.warning" class="fmico fmico-paixu-shengxu"></i></div>
        <div class="info-text">总价：{{v.total}}元<i v-if="item.rate1Data.warning" class="fmico fmico-paixu-shengxu"></i></div>
      </div>
    </div>
  </div>
</div>
<div v-else>无关联预算</div>
</template>

<script>
import rate from './rate'

import {
  budgetDetailRequest
} from '@/api'

export default {
  components: {
    rate
  },
  props: {
    budgetDetailIds: { type: Array, defualt: () => [] },
    type: { type: String},
    purchaseBatchDetaiId: {
      type: Number,
      defualt: null
    }
  },
  watch: {
    budgetDetailIds: {
      deep: true,
      handler () {
        this.loadData()
      },
      immediate: true
    }
  },
  data () {
    return {
      budgetDetailList: []
    }
  },
  methods: {
    getRate1Data (item, title, key) {
      let data = {
        warning: false,
        title: title,
        data: [],
        dw: key === 'num' ? '' : '元',
        color: ['#F5E2DF', '#FFCFD7', '#FF9EA3']
      }
      let totalM = item[key]
      let usedTotalM = 0
      let usedList = item.purchaseBatchDetailList.map(v => {
        usedTotalM += v[key] || 0
        return {
          name: v.code,
          value: v[key] || 0
        }
      })
      if (totalM === usedTotalM) {
        data.data = usedList;
      } else if (totalM > usedTotalM) {
        data.data = usedList;
        data.data.push({
          name: '剩余',
          value: totalM - usedTotalM,
          itemStyle: {
            color: '#A0DBE3'
          },
          label: {
            show: true,
            position: 'center',
            formatter: function (param) {
              return param.name + ' ' + param.value + (data.dw || '')
            }
          }
        })
      } else {
        data.warning = true
        data.data.push({
          name: '超出',
          value: usedTotalM - totalM,
          itemStyle: {
            color: '#FB6D8F'
          },
          label: {
            show: true,
            position: 'center',
            formatter: function (param) {
              return param.name + ' ' + param.value + (data.dw || '')
            }
          }
        })
      }
      return data
    },
    async loadData () {
      this.budgetDetailList = []
      if (this.budgetDetailIds.length === 0) {
        return
      }
      let datas = await budgetDetailRequest.get({
        needPurchaseBatchDetail: 1,
        ids: this.budgetDetailIds.join(',')
      })
      datas.forEach(v => {
        v.rate1Data = this.getRate1Data(v, '总价', 'total')
        v.rate2Data = this.getRate1Data(v, '数量', 'num')
      })
      this.budgetDetailList = datas
    },
  }
}
</script>

<style scoped lang="less">
.purchase-detail-bdi-list {
  width: 100%;
  .budget-info {
    min-width: 150px;
    .info-text {
      line-height: 22px;
    }
    i {
      color: #FB6D8F;
      padding-left: 10px;
    }
  }
  .purchase-detail-bdi {
    display: flex;
    height: 190px;
  }
  .p-chart {
    display: flex;
    width: 280px;
    padding-right: 20px;
    height: 100%;
    .chart-item {
      width: 50%;
      height: 100%;
    }
  }
  .detail-list {
    display: flex;
    width: calc(100% - 280px);
    overflow-x: auto;
  }
  .detail-list-c {
    width: calc(100%);
  }
}
</style>